.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.TotalAllocated {
  color: #6a6a6a;
}
.TotalAmount{
  color: #007ed9;
}
.TotalSpent {
  color: #007ed9;
}
.widget5 .TenderAmounts {
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spentvsAllocated {
  font-size: 27px;
  padding: 0px 0px;
  /* background-color: #fff; */
  margin: 15px 0;
  font-weight: 600;
}
.spentvsAllocated .sp-1 {
  color: #309eca;
}

.spentvsAllocated .sp-3 {
  color: #3992f7;
}
.spentvsAllocated .sp-4{
  color: #566aab;
}
.spentvsAllocated .sp-5{
  color: #000;
}
.spentvsAllocated .sp-6{
  color: #38ca7a;
}

.spentvsAllocated .sp-7{
  color: #6a6a6a;
}

.spentvsAllocated .sp-8{
  color: #000;
}

.spentvsAllocated .sp-9{
  color: #257fda;
}




.spentvsAllocated .sp-2 {
  background: #03a9f4;
  color: #7c5454;
  border-radius: 50px;
  text-align: center;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 34px;
  color: #fff;
}
.qmonth{
    display: inline-block;
    border: 1px solid #989090;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 10px;
    margin: 0 !important;

}
.qDataBox{
  height: 55px;
}
.hangon {
  /* display: flex; */
  justify-content: space-between;
  position: absolute;
  top: 100px;
  width: 100%;
  text-align: center;
  display: block;
  left: 0;
  z-index: 99999;
}
.hourglass{
  background: #206bc4;
    padding: 5px;
    border-radius: 7px;
    margin-right: 2px;
}
.totalBudgetAllocatedData{

}

 table.table{ border: 0;  border-collapse:separate; 
  border-spacing: 0 .5em; margin-top: -1em !important; }
table.table thead tr{ background: #3992f7; color: #fff; }
table.table thead tr th{color: #fff; border: 0; font-size: 14px;}
table.table tbody tr{background: #eef7fc; border-radius: 10px;}
table.table tbody tr:nth-child(even) {background-color: #fef2f2;}
table.table tbody tr td, table.table thead tr th{ padding: 10px 5px; border: 0; font-size: 14px; background: transparent !important; }
table.table p{ margin: 0; }


table.table thead tr th:first-child { border-top-left-radius: 6px;  border-bottom-left-radius: 6px;}
table.table thead tr th:last-child { border-top-right-radius: 6px; border-bottom-right-radius: 6px; }

table.table tbody tr th:first-child { border-top-left-radius: 6px;  border-bottom-left-radius: 6px;}
table.table tbody tr td:last-child { border-top-right-radius: 6px; border-bottom-right-radius: 6px; }

.tendersBox{
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
}

#root > div{ background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(22,20,54,1) 5%, rgba(237,237,237,1) 5%, rgba(237,237,237,1) 100%) !important;}

.headingBox {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 10px 15px;
}